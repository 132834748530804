import React, { useState } from "react";
import Layout from "../../../../components/Layout";
import Seo from "../../../../components/seo";
import HeroBgImage from "../../../../images/aichapter/sara/Hero-banner-Sara.png";
import FinanceBgImg from "../../../../images/aichapter/sara/Saraa-finance.png";
import FinaneSVG from "../../../../images/aichapter/sara/Finance.svg";
import BusinesssesBgImg from "../../../../images/aichapter/sara/Saraa-business.png";
import BusinesssesSVG from "../../../../images/aichapter/sara/business.svg";
import DataAnalystBgImg from "../../../../images/aichapter/sara/Saraa-data-analyst.png";
import DataAnalystSVG from "../../../../images/aichapter/sara/Data-analysis.svg";
import DeveloperBgImg from "../../../../images/aichapter/sara/Saraa-developer.png";
import DeveloperSVG from "../../../../images/aichapter/sara/Developers.svg";
import DataBaseAdminBgImg from "../../../../images/aichapter/sara/Saraa-database.png";
import DataBaseAdminSVG from "../../../../images/aichapter/sara/DATA-Admin.svg";
// import AppSaraVideo from "../../../../images/aichapter/videos/app-sara-1.mp4";
import AppSaraVideo from "../../../../images/aichapter/videos/app-sara-updated-video.mp4";
import FeaturedSaraImg from "../../../../images/aichapter/sara/Saraa-Illustration.svg";
import ogIMage from "../../../../images/aichapter/sara/OG-image_Sara.png";
import FeatureViduraOneSVG from "../../../../images/aichapter/feature-vidura-1.svg";
import FeatureViduraTwoSVG from "../../../../images/aichapter/feature-vidura-2.svg";
import FeatureViduraThreeSVG from "../../../../images/aichapter/feature-vidura-3.svg";
import FeatureViduraFourSVG from "../../../../images/aichapter/feature-vidura-4.svg";
import FeatureViduraFiveSVG from "../../../../images/aichapter/feature-vidura-5.svg";
import * as SaraEffeciencyAnimation from "../../../../animations/sara-efficiency.json";
import * as SaraCustomizationAnimation from "../../../../animations/sara-customization.json";
import * as SaraSeamlessIntegration from "../../../../animations/sara-seamless-integration.json";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import FeaturesOfDemos from "../../../../components/FeaturesOfDemos";
import Lottie from "react-lottie";
import NavigateBlockV2 from "../../../../components/NavigateBlockV2";
import ExplainedDemoAI from "../../../../components/ExplainedDemoAI";
import { Helmet } from "react-helmet";
import CountIncrement from "../../../../components/CountIncrement";

const SARAPage = () => {
    // const [showSaraChatbot, setShowSaraChatbot] = useState(false);
    // const handleSaraChatbot = (e) => {
    //     e.preventDefault();
    //     setShowSaraChatbot(true);
    // };
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };
    const ListOfSaraFeatures = [
        {
            id: 1,
            imageSrc: FeatureViduraOneSVG,
            title: "AI-Driven Insights",
            description:
                "Sara uses advanced AI to interpret user questions and generate accurate SQL queries, ensuring precise data retrieval.",
        },
        {
            id: 2,
            imageSrc: FeatureViduraTwoSVG,
            title: "Dynamic Query Generation",
            description:
                "Automatically create complex SQL queries tailored to your needs, reducing manual effort and errors.",
        },
        {
            id: 3,
            imageSrc: FeatureViduraThreeSVG,
            title: "User-Friendly Interface",
            description:
                "Sara’s intuitive interface makes data retrieval as simple as asking a question, perfect for users of all skill levels.",
        },
        {
            id: 4,
            imageSrc: FeatureViduraFourSVG,
            title: "Comprehensive Data Support",
            description:
                "Connect with multiple database types and handle various data formats, including spreadsheets, for versatile data management.",
        },
        {
            id: 5,
            imageSrc: FeatureViduraFiveSVG,
            title: "Customizable Workflows",
            description:
                "Train Sara to understand your specific data structures and business requirements for tailored results.",
        },
    ];
    const ListOfExplainAI = [
        {
            id: 1,
            btnTitle: "Finance",
            svgImg: FinaneSVG,
            title: "Make Smarter Decisions",
            content:
                "Optimized to manage and analyze time-stamped financial data, like stock prices and transaction histories, effectively within specific time frames. This enhances the performance and outcomes of financial AI applications.",
            image: FinanceBgImg,
        },
        {
            id: 2,
            btnTitle: "Businesses",
            svgImg: BusinesssesSVG,
            title: "Effortless Data Retrieval and Analysis",
            content:
                "Perfect for businesses needing quick and accurate data insights, Sara allows you to generate complex SQL queries without technical expertise. Say goodbye to manual data extraction and hello to automated, reliable results.",
            image: BusinesssesBgImg,
        },
        {
            id: 3,
            btnTitle: "Data Analysts",
            svgImg: DataAnalystSVG,
            title: "Enhanced Efficiency in Data Handling",
            content:
                "For data analysts, Sara streamlines the data retrieval process, saving valuable time and effort. Generate detailed reports and analyses with ease, and focus on deriving insights rather than wrestling with data queries.",
            image: DataAnalystBgImg,
        },
        {
            id: 4,
            btnTitle: "Developers",
            svgImg: DeveloperSVG,
            title: "Optimized SQL Query Generation",
            content:
                "Developers can leverage Sara to automate the creation of SQL queries, improving productivity and ensuring error-free results. Whether you're working on a small project or a large-scale application, it enhances your data handling capabilities.",
            image: DeveloperBgImg,
        },
        {
            id: 5,
            btnTitle: "Database Administrators",
            svgImg: DataBaseAdminSVG,
            title: "Simplified Database Management",
            content:
                "Database administrators benefit from Sara’s ability to automate complex query generation, making database management more efficient. Reduce the time spent on routine tasks and focus on optimizing database performance.",
            image: DataBaseAdminBgImg,
        },
    ];
    return (
        <Layout NavbarTheme={NavbarTheme}>
            <Helmet>
                <script
                    async
                    src="https://d1fmfone96g0x2.cloudfront.net/tarka-chat-2.4.0.umd.js"
                ></script>
            </Helmet>
            <Seo
                title="Intelligent AI solution SQL Assistant Sara- Sedin"
                description="Discover SARA, the AI-powered SQL assistant for generating dynamic queries & fetching precise data, transforming your database interactions with ease and accuracy."
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section
                className="AI-common-herostyle vidura-ai"
                style={{
                    background: `url(${HeroBgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                <div className="container container--banner">
                    <div className="AI-common-herostyle__text">
                        <div className="AI-common-herostyle__text-breadcrumbs text-fw-normal gap-2x text-clr-primary text-clr-white">
                            <Link className="text-decor-none" to="">
                                AI Chapter
                            </Link>
                            /
                            <Link className="text-decor-none" to="/ai-chapter/solutions">
                                Solutions
                            </Link>
                            /
                            <Link className="text-decor-none" to="">
                                Sara
                            </Link>
                        </div>
                        <h1 className="text-h1 AI-common-herostyle__text-header text-fw-medium gap-2x text-clr-white">
                            Sara
                        </h1>
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Revolutionize Your Data Management
                        </p>
                        <div>
                            <Link className="sdn-cta-btn" to="/contact/">
                                Contact us
                            </Link>
                        </div>
                    </div>
                    <div className="ai-demos-stats">
                        <p className="text-p1 AI-common-herostyle__text-description text-fw-regular gap-2x text-clr-white">
                            Simplify complex data interactions. Sara makes informed decisions
                            faster.
                        </p>
                        <div className="ai-demos-stats__grid">
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    {/* 60% */}
                                    <CountIncrement start={0} end={98} duration={3000} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Accuracy in databases
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={100} duration={3500} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    GenAI/ML powered data engineering
                                </p>
                            </div>
                            <div className="ai-demos-stats__grid-item">
                                <h1 className="text-h1 AI-common-herostyle__text-description text-fw-medium gap-05x text-clr-white">
                                    <CountIncrement start={0} end={100} duration={3500} />
                                </h1>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Guaranteed Supported In Data Retrieval
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="leverage-genAI-content demos-content">
                <div className="container container--first">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-3x">
                            Open and Trusted AI Platform to Transform Data Queries into Actionable
                            Insights
                        </div>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-1x">
                            Introducing our cutting-edge AI solution - SQL Assistant for Retrieval
                            and Analysis (SARA). We're dedicated to transforming your data
                            interactions by harnessing the power of AI. Sara simplifies data
                            retrieval by generating SQL queries and presenting results in an
                            intuitive format, enabling you to make informed decisions effortlessly.
                            With support for multiple languages and formats, our AI solution ensures
                            you get the information you need in the most efficient way possible.
                        </p>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-2x">
                            Unlock the full potential of your structured database systems with Sara,
                            the SQL Assistant for Retrieval and Analysis. Seamlessly create dynamic
                            SQL queries and fetch precise data tailored to your specific questions
                            using advanced AI technology.
                        </p>
                    </div>
                </div>
            </section>
            {/* vidura explained  */}
            <section className="demo-explain-sec">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-4x explain-word">
                            Sara Expl <span className="AI-word">ai</span>ned
                        </div>
                    </div>
                    <div className="">
                        <ExplainedDemoAI ListOfExplainAI={ListOfExplainAI} />
                    </div>
                </div>
            </section>
            <section className="Demos-video-sec">
                <div className="container container--mid-v2">
                    <div className="leverage-genAI-content__text">
                        <div className="text-h1 text-fw-medium gap-4x">
                            Future of Data Retrieval is here.
                        </div>
                        <div className="Demos-video-sec__demovideo">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                <video
                                    loop
                                    autoPlay
                                    muted
                                    playsInline
                                    controlsList="nodownload"
                                    className="ai-video-sara gap-2x"
                                >
                                    <source src={AppSaraVideo} type="video/mp4" />
                                </video>
                            </div>
                            {/* <div>
                                <button
                                    onClick={(e) => handleSaraChatbot(e)}
                                    target="no_refer"
                                    className="sdn-cta-btn"
                                >
                                    Try Sara Now
                                </button>
                                {showSitaChatbot && <SitaChatbotWrapper />}
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* features of vidura  */}
            <section className="features-of-demos">
                <div className="container container--mid-v2">
                    <div className="features-of-demos__wrapper">
                        <div className="features-of-demos__grid">
                            <div className="features-of-demos__grid-item-left">
                                <div className="sticky-container">
                                    <h2 className="text-h1 text-fw-medium text-clr-primary gap-2x">
                                        Features of Sara
                                    </h2>
                                    <div className="feature-img">
                                        <img
                                            src={FeaturedSaraImg}
                                            alt="feature-img"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="features-of-demos__grid-item-right-containerbox">
                                <FeaturesOfDemos featuresList={ListOfSaraFeatures} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-demos-sec">
                <div className="container container--mid-v2">
                    <div className="text-h1 text-fw-medium gap-4x">Why Sara?</div>
                    <div className="why-demos-sec__grid">
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: SaraEffeciencyAnimation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Efficiency
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Save time and resources with automated data queries and seamless
                                    data integration.
                                </p>
                            </div>
                        </div>
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: SaraCustomizationAnimation,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Customization
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Tailor your data queries to meet specific needs, providing
                                    relevant and actionable insights.
                                </p>
                            </div>
                        </div>
                        <div className="why-demos-sec__grid-item">
                            <div className="animation">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: SaraSeamlessIntegration,
                                        rendererSettings: {
                                            preserveAspectRatio: "xMidYMid slice",
                                        },
                                    }}
                                />
                            </div>
                            <div className="content">
                                <h3 className="text-h3 text-fw-medium text-clr-primary gap-1x">
                                    Seamless Integration
                                </h3>
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Easily integrate Sara with your existing database systems for
                                    smooth data handling.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="navigate-cta">
                <div className="container container--last-v2">
                    <NavigateBlockV2
                        button="Contact us"
                        title="Ready to Transform Your Data Queries Interaction?"
                    />
                </div>
            </section>
        </Layout>
    );
};

export default SARAPage;
